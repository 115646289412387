import React from "react"

import SEO from "../components/seo"

import "../sass/pages/_contact.scss"

const ContactPage = () => (
  <>
    <SEO title="Contact" />
    <p>
      Email us at{" "}
      <a href="MAILTO:theorchestracollective@gmail.com" className="email" data-text="theorchestracollective@gmail.com" >
        theorchestracollective@gmail.com
      </a>
    </p>
  </>
)

export default ContactPage
